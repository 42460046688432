.ant-btn:not(:last-child) {
  margin-right: 4px;
  margin-top: 5px;
  align-items: center;
}
.ant-badge {
  margin: 0 5px;
}
.ant-btn:not([disabled]):not(.disabled) {
  margin-top: 5px;
}
.ant-popover-arrow {
  display: none;
}
// .ant-btn:hover, .ant-btn:focus {
//   color: #fff;
// }

.bg-blue {
  background-color: #3abaf4 !important;
  outline: #3abaf4 !important;
  border: #3abaf4 !important;
  color: white;
}
.bg-warning {
  background-color: #ffa426 !important;
  outline: #ffa426 !important;
  border: #ffa426 !important;
  color: white;
}
.bg-purple {
  background-color: #9852a5 !important;
  outline: #9852a5 !important;
  border: #9852a5 !important;
  color: white;
}
.bg-danger {
  background-color: #dd0d0d !important;
  outline: #dd0d0d !important;
  border: #dd0d0d !important;
  color: white;
}
.bg-success {
  background-color: #44a789 !important;
  outline: #44a789 !important;
  border: #44a789 !important;
  color: white;
}
.bg-orange {
  background-color: #fc544b !important;
  outline: #fc544b !important;
  border: #fc544b !important;
  color: white;
}
.bg-green {
  background-color: #54ca68 !important;
  outline: #54ca68 !important;
  border: #54ca68 !important;
  color: white;
}
.bg-darkBlue {
  background-color: #5864bd !important;
  outline: #5864bd !important;
  border: #5864bd !important;
  color: white;
}
.admission-card {
  margin: 18px auto;
  background-color: white;
  border-radius: 10px;
  border-top: 4px solid red;
  height: 191px;
  width: 100%;
  height: auto;
  padding: 20px;
}

.header-icon {
  width: 30px;
  height: 30px;
  font-size: 23px;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 4px;
}
.popup {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  color: #5864bd;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  .popup-icon {
    font-size: 16px;
  }
  p {
    margin-bottom: 0;
  }
}
.edit-icon-wrapper{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  top: 25px;
  right: 50px;
  background-color: #5864bd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.title{
  font-weight: 600;
}
@media screen {
  @media (max-width: 768px) {
    .card-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
}
