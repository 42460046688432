.float-label {
    position: relative;
    padding-bottom: 5px;
  }

  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 18px;
    top: 13px;
    transition: 0.2s ease all;
    letter-spacing: .7px;
}
  
  .as-placeholder {
    color: gray;
  }
  
  .text-danger{
    color: red;
  }
.as-label {
    top: -6px;
    font-size: 12px !important;
    background: white;
    padding: 0 4px;
    margin-left: -5px;
    color: #ea5455;
}
  