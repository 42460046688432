.header-column {
    padding: 10px;
    text-align: center;
    background: #ea5455;
    color: #fff;
    font-weight: bold;
    padding: 8px 10px;
    font-size: 15px;
    word-break: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    min-width: 100px;
}

.header-column::before {
    width: 1px;
    top: 15%;
    height: 70%;
    content: '';
    position: absolute;
    right: 0;
    background: #ffffff;
}

.header-column-time {
    display: flex;
}

.faculty-available-slot-container {
    display: grid;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

.body-column {
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    word-break: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
}

.body-column-time-slot {
    position: absolute;
    padding: 10px;
    overflow: hidden;
    word-break: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 2;
}

.body-column-time {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
}

.body-column-time-slot-border {
    width: 100%;
    border-right: 1px solid rgb(161, 161, 161);
}

.even-row {
    background-color: #fff8f8;
}

.odd-row {
    background-color: #ffffff;
}