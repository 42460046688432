/*Add Admission Styles*/

.@{class-prefix}-page-title {
  display: inline-block;
  font-size: @size-18;
  font-weight: @bold-600;
  border: 1px solid @purple-color;
  padding: 10px 10px ;
  border-radius: 4px;
}
.@{class-prefix}-page-title-sub {
  display: inline-block;
  font-size: 16px;
  font-weight: @bold-600;
  margin-bottom: 0;
}
.@{class-prefix}-card {
  background-color: @white-color;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
}
.@{class-prefix}-container-wrapper {
  border: 1px solid #722ed1;
  padding: 27px 25px 0;
  border-radius: 5px;
  margin-top: 30px;
}
.@{class-prefix}-field-wrapper {
  margin: 0 15px;
}
.@{class-prefix}-plus-icon-wrapper {
  font-size: @size-32;
  color: #000000;
  background: #8e5ff2;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 50%;
}
.@{class-prefix}-double-right-icon-wrapper {
  color: #000000;
  background: #8e5ff2;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.@{class-prefix}-mt-100 {
  margin-top: 100px;
}
.@{class-prefix}-top-51 {
  top: 51%;
}
.@{class-prefix}-button-wrapper {
  margin-bottom: 15px !important;
}
.@{class-prefix}-double-right-icon {
  width: 30px;
  font-size: @size-24;
}
.@{class-prefix}-make-installment-btn {
  margin-top: -22px;
  background-color: @purple-color !important;
  border-color: @purple-color !important;
}
.@{class-prefix}-make-installment-btn:hover {
  background-color: @purple-color !important;
  border-color: @purple-color !important;
}
.@{class-prefix}-make-installment-btn:active {
  background-color: @purple-color !important;
  border-color: @purple-color !important;
  background: @purple-color !important;
}
.@{class-prefix}-make-installment-btn:focus {
  background-color: @purple-color !important;
  border-color: @purple-color !important;
  background: @purple-color !important;
}
.@{class-prefix}-make-installment-btn:not([disabled]):not(.disabled):active {
  background-color: @purple-color !important;
  border-color: @purple-color !important;
  background: @purple-color !important;
}
